nav[data-page='home'] a {
  color: white;
  transition: all 1s ease;
}

nav[data-page='home'] [data-nav='hamburger']::before,
nav[data-page='home'] [data-nav='hamburger']::after {
  background: white;
}

nav[data-page='home'].section--active a {
  color: black !important;
}

nav[data-page='home'].section--active [data-nav='hamburger']::before,
nav[data-page='home'].section--active [data-nav='hamburger']::after {
  background: black;
}
